// import React from "react";
// export let AuthContext = React.createContext();

// auth.js
// import React, { createContext, useState } from "react";

// // Create the AuthContext
// export const AuthContext = createContext();

// // AuthProvider component
// export const AuthProvider = ({ children }) => {
//   const [authState, setAuthState] = useState({
//     token: null,
//     userData: null,
//   });

//   const login = (data) => {
//     setAuthState(data);
//     // you can also save token to localStorage or perform other login operations
//   };

//   const logout = () => {
//     setAuthState({ token: null, userData: null });
//     // remove token from localStorage or perform other logout operations
//   };

//   return (
//     <AuthContext.Provider value={{ ...authState, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// import React, { createContext, useState } from "react";

// // Create the AuthContext
// export const AuthContext = createContext();

// // AuthProvider component
// export const AuthProvider = ({ children }) => {
//   const [authState, setAuthState] = useState({
//     token: null,
//     userData: null,
//   });

//   const login = (data) => {
//     setAuthState(data);
//     // you can also save token to localStorage or perform other login operations
//   };

//   const logout = () => {
//     setAuthState({ token: null, userData: null });
//     // remove token from localStorage or perform other logout operations
//   };

//   return (
//     <AuthContext.Provider value={{ ...authState, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// AuthContext.js
// import React, { createContext, useState } from "react";

// // Create the AuthContext
// export const AuthContext = createContext();

// // AuthProvider component
// export const AuthProvider = ({ children }) => {
//   const [authState, setAuthState] = useState({
//     token: null,
//     userData: null,
//   });

//   const login = (data) => {
//     setAuthState(data);
//     localStorage.setItem('firebaseToken', data.token); // Save token to localStorage
//   };

//   const logout = () => {
//     setAuthState({ token: null, userData: null });
//     localStorage.removeItem('firebaseToken'); // Remove token from localStorage
//   };

//   return (
//     <AuthContext.Provider value={{ ...authState, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// add to cart
// import React, { createContext, useState, useEffect } from "react";
// import axios from "axios";
// import { baseUrl } from "../Constant/constant";

// // Create the AuthContext
// export const AuthContext = createContext();

// // AuthProvider component
// export const AuthProvider = ({ children }) => {
//   const [authState, setAuthState] = useState({
//     token: null,
//     userData: null,
//   });

//   // Check localStorage for token on initial load
//   useEffect(() => {
//     const token = localStorage.getItem("firebaseToken");
//     if (token) {
//       // Fetch user data from your backend using the token
//       const fetchUserData = async () => {
//         try {
//           const userId = "66bb3799b774be65854522e9";
//           const response = await axios.get(`${baseUrl}/user/profile/${userId}`, {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           });

//           const userData = response.data.data[0];
//           console.log("user datatata :", userData)

//           setAuthState({ token, userData });
//         } catch (error) {
//           console.error("Failed to fetch user data:", error);
//         }
//       };

//       fetchUserData();
//     }
//   }, []);

//   const login = (data) => {
//     setAuthState(data);
//     localStorage.setItem("firebaseToken", data.token); // Save token to localStorage
//   };

//   const logout = () => {
//     setAuthState({ token: null, userData: null });
//     localStorage.removeItem("firebaseToken"); // Remove token from localStorage
//   };

//   return (
//     <AuthContext.Provider value={{ ...authState, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// review
// import React, { createContext, useState, useEffect } from "react";
// import axios from "axios";
// import { baseUrl } from "../Constant/constant";

// // Create the AuthContext
// export const AuthContext = createContext();

// // AuthProvider component
// export const AuthProvider = ({ children }) => {
//   const [authState, setAuthState] = useState({
//     token: null,
//     userData: null,
//   });

//   // Check localStorage for token on initial load
//   useEffect(() => {
//     const token = localStorage.getItem("firebaseToken");
//     if (token) {
//       // Fetch user data from your backend using the token
//       const fetchUserData = async () => {
//         try {
//           const userId = "66bb3799b774be65854522e9";
//           const response = await axios.get(`${baseUrl}/user/profile/${userId}`, {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           });

//           const userData = response.data.data[0];
//           console.log("Fetched user data auth.js:", userData);

//           if (userData) {
//             setAuthState({ token, userData });
//           } else {
//             console.error("User data not found or invalid format");
//           }
//         } catch (error) {
//           console.error("Error fetching user data:", error);
//         }
//       };

//       fetchUserData();
//     }
//   }, []);

//   const login = (data) => {
//     setAuthState(data);
//     localStorage.setItem("firebaseToken", data.token); // Save token to localStorage
//   };

//   const logout = () => {
//     setAuthState({ token: null, userData: null });
//     localStorage.removeItem("firebaseToken"); // Remove token from localStorage
//   };

//   return (
//     <AuthContext.Provider value={{ ...authState, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// footer logo old wala
// import React, { createContext, useState, useEffect } from "react";
//  import axios from "axios";
//  import { baseUrl } from "../Constant/constant";
//  export const AuthContext = createContext();
// export const AuthProvider = ({ children }) => {
//   const [authState, setAuthState] = useState({
//     token: null,
//     userData: null,
//     vendorId: null,
//   });

//   useEffect(() => {
//     const token = localStorage.getItem("firebaseToken");
//     if (token) {
//       const fetchUserData = async () => {
//         try {
//           const userId = "66bb3799b774be65854522e9";
//           const response = await axios.get(`${baseUrl}/user/profile/${userId}`, {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           });

//           const userData = response.data.data[0];
//           console.log("Fetched user data auth.js:", userData);

//           if (userData) {
//             setAuthState({
//               token,
//               userData,
//               vendorId: userData.vendorId
//             });
//           } else {
//             console.error("User data not found or invalid format");
//           }
//         } catch (error) {
//           console.error("Error fetching user data:", error);
//         }
//       };

//       fetchUserData();
//     }
//   }, []);

//   const login = (data) => {
//     setAuthState(data);
//     localStorage.setItem("firebaseToken", data.token);
//   };

//   const logout = () => {
//     setAuthState({ token: null, userData: null, vendorId: null });
//     localStorage.removeItem("firebaseToken");
//   };

//   return (
//     <AuthContext.Provider value={{ ...authState, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// updated one with handling
// import React, { createContext, useState, useEffect, useCallback } from "react";
// import axios from "axios";
// import { baseUrl } from "../Constant/constant";

// export const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [authState, setAuthState] = useState({
//     token: null,
//     userData: null,
//     // vendorId: null,
//     vendorId: "664b1de93dad8bcb3a9b35f0",
//   });
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem("firebaseToken");
//     if (token) {
//       const fetchUserData = async () => {
//         try {
//           const userId = "66bb3799b774be65854522e9";
//           const response = await axios.get(`${baseUrl}/user/profile/${userId}`, {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           });

//           const userData = response.data.data[0];
//           console.log("Fetched user data auth.js:", userData);

//           if (userData) {
//             setAuthState({
//               token,
//               userData,
//               // vendorId: userData.vendorId
//               vendorId:"664b1de93dad8bcb3a9b35f0"
//             });
//           } else {
//             console.error("User data not found or invalid format");
//           }
//         } catch (error) {
//           console.error("Error fetching user data:", error);
//         } finally {
//           setLoading(false);
//         }
//       };

//       fetchUserData();
//     } else {
//       setLoading(false);
//     }
//   }, []);

//   const login = useCallback((data) => {
//     setAuthState(data);
//     localStorage.setItem("firebaseToken", data.token);
//   }, []);

//   const logout = useCallback(() => {
//     setAuthState({ token: null, userData: null, vendorId: null });
//     localStorage.removeItem("firebaseToken");
//   }, []);

//   return (
//     <AuthContext.Provider value={{ ...authState, login, logout, loading }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// without login
// import React, { createContext, useState, useEffect, useCallback } from "react";
// import axios from "axios";
// import { baseUrl } from "../Constant/constant";

// export const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [authState, setAuthState] = useState({
//     token: null,
//     userData: null,
//     vendorId: "664b1de93dad8bcb3a9b35f0",  // asawari
//     //vendorId: "66d6dd9bba60ab199b61ad24",     // darshan sir
//   });
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem("firebaseToken");
//     if (token) {
//       const fetchUserData = async () => {
//         try {
//           // const userId = "66bb3799b774be65854522e9";
//           const userId = "";
//           const response = await axios.get(`${baseUrl}/user/profile/${userId}`, {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           });

//           const userData = response.data.data[0];
//           console.log("Fetched user data auth.js:", userData);

//           if (userData) {
//             setAuthState({
//               token,
//               userData,
//               vendorId: "664b1de93dad8bcb3a9b35f0",  // asawari
//               // vendorId: "66d6dd9bba60ab199b61ad24",     // darshan sir
//             });
//           } else {
//             console.error("User data not found or invalid format");
//           }
//         } catch (error) {
//           console.error("Error fetching user data:", error);
//         } finally {
//           setLoading(false);
//         }
//       };

//       fetchUserData();
//     } else {
//       setLoading(false);
//     }
//   }, []);

//   const login = useCallback((data) => {
//     setAuthState({
//       ...data,
//       vendorId: "664b1de93dad8bcb3a9b35f0",  // aasawari
//       // vendorId: "66d6dd9bba60ab199b61ad24",     // darshan sir
//     });
//     localStorage.setItem("firebaseToken", data.token);
//   }, []);

//   const logout = useCallback(() => {
//     setAuthState({
//       token: null,
//       userData: null,
//       vendorId: "664b1de93dad8bcb3a9b35f0",  // asawari
//       // vendorId: "66d6dd9bba60ab199b61ad24",     // darshan sir
//     });
//     localStorage.removeItem("firebaseToken");
//   }, []);

//   return (
//     <AuthContext.Provider value={{ ...authState, login, logout, loading }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// api
// import React, { createContext, useState, useEffect, useCallback } from "react";
// import axios from "axios";
// import { baseUrl } from "../Constant/constant";

// export const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [authState, setAuthState] = useState({
//     token: null,
//     userData: null,
//     userId: null,
//     // vendorId: "664b1de93dad8bcb3a9b35f0",  // asawari
//     vendorId: "66d6dd9bba60ab199b61ad24",  // darshan sir
//   });
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem("authToken");
//     const userId = localStorage.getItem("userId");
//     if (token && userId) {
//       fetchUserData(token, userId);
//     } else {
//       setLoading(false);
//     }
//   }, []);

//   const fetchUserData = async (token, userId) => {
//     try {
//       const response = await axios.get(`${baseUrl}/user/profile/${userId}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       const userData = response.data.data[0];
//       console.log("Fetched user data from auth:", userData);

//       if (userData) {
//         setAuthState({
//           token,
//           userData,
//           userId,
//           // vendorId: "664b1de93dad8bcb3a9b35f0",
//           vendorId: "66d6dd9bba60ab199b61ad24",
//         });
//       } else {
//         console.error("User data not found or invalid format");
//       }
//     } catch (error) {
//       console.error("Error fetching user data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const login = useCallback((data) => {
//     setAuthState({
//       token: data.token,
//       userData: data.userData,
//       userId: data.userData.userId,
//       // vendorId: "664b1de93dad8bcb3a9b35f0",
//       vendorId: "66d6dd9bba60ab199b61ad24",
//     });
//     localStorage.setItem("authToken", data.token);
//     localStorage.setItem("userId", data.userData.userId);
//   }, []);

//   const logout = useCallback(() => {
//     setAuthState({
//       token: null,
//       userData: null,
//       userId: null,
//       // vendorId: "664b1de93dad8bcb3a9b35f0",
//       vendorId: "66d6dd9bba60ab199b61ad24",
//     });
//     localStorage.removeItem("authToken");
//     localStorage.removeItem("userId");
//   }, []);

//   return (
//     <AuthContext.Provider value={{ ...authState, login, logout, loading }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// remove unnecessary api
import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import axios from "axios";
import { baseUrl } from "../Constant/constant";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: null,
    userData: null,
    userId: null,
    // vendorId: "66d6dd9bba60ab199b61ad24",  // darshan sir
    // vendorId: "673329ff2addc02a97c693a3",  // darshan sir
    vendorId: "673b11765979773592157e1e", //main kplanet
    // vendorId: "673dc4d62cbb36699379368d", //dev
  });

  const [loading, setLoading] = useState(true);

  const fetchUserData = useCallback(async (token, userId) => {
    try {
      const response = await axios.get(`${baseUrl}/user/profile/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const userData = response.data.data[0];

      if (userData) {
        setAuthState((prevState) => ({
          ...prevState,
          token,
          userData,
          userId,
        }));
      } else {
        console.error("User data not found or invalid format");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
    if (token && userId) {
      fetchUserData(token, userId);
    } else {
      setLoading(false);
    }
  }, [fetchUserData]);

  const login = useCallback((data) => {
    setAuthState((prevState) => ({
      ...prevState,
      token: data.token,
      userData: data.userData,
      userId: data.userData.userId,
    }));
    localStorage.setItem("authToken", data.token);
    localStorage.setItem("userId", data.userData.userId);
  }, []);

  const logout = useCallback(() => {
    setAuthState((prevState) => ({
      ...prevState,
      token: null,
      userData: null,
      userId: null,
    }));
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
  }, []);

  const contextValue = useMemo(
    () => ({
      ...authState,
      login,
      logout,
      loading,
    }),
    [authState, login, logout, loading]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
