import React, { createContext, useState, useContext, useEffect } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  // Initialize cart count from localStorage or default to 0
  const [cartCount, setCartCount] = useState(() => {
    const storedCartCount = localStorage.getItem('cartCount');
    return storedCartCount ? parseInt(storedCartCount, 10) : 0;
  });

  // Update both state and localStorage when the cart count changes
  const updateCartCount = (count) => {
    setCartCount(count);
    localStorage.setItem('cartCount', count);
  };

  // New function to reset cart count to 0 after order placement
  const resetCart = () => {
    setCartCount(0);
    localStorage.setItem('cartCount', '0');
  };

  // Ensure cart count is synced from localStorage on initial load
  useEffect(() => {
    const storedCartCount = localStorage.getItem('cartCount');
    if (storedCartCount) {
      setCartCount(parseInt(storedCartCount, 10));
    }
  }, []);

  return (
    <CartContext.Provider value={{ cartCount, updateCartCount, resetCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);