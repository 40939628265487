// import cogoToast from 'cogo-toast';
// const { createSlice } = require('@reduxjs/toolkit');

// const wishlistSlice = createSlice({
//     name: "wishlist",
//     initialState: {
//         wishlistItems: []
//     },
//     reducers: {
//         addToWishlist(state, action) {
//             const isInWishlist = state.wishlistItems.findIndex(item => item.id === action.payload.id);
//             if(isInWishlist > -1){
//                 cogoToast.info("Product already in wishlist", {position: "bottom-left"});
//             } else {
//                 state.wishlistItems.push(action.payload);
//                 cogoToast.success("Added To wishlist", {position: "bottom-left"});
//             }
            
//         },
//         deleteFromWishlist(state, action){
//             state.wishlistItems = state.wishlistItems.filter(item => item.id !== action.payload);
//             cogoToast.error("Removed From Wishlist", {position: "bottom-left"});
//         },
//         deleteAllFromWishlist(state){
//             state.wishlistItems = []
//         }
//     },
// });

// export const { addToWishlist, deleteFromWishlist, deleteAllFromWishlist } = wishlistSlice.actions;
// export default wishlistSlice.reducer;





// simple one 
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import cogoToast from 'cogo-toast';
import { baseUrl } from '../../Constant/constant';

// Existing async thunks
export const fetchWishlistData = createAsyncThunk(
  'wishlist/fetchWishlistData',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await fetch(`${baseUrl}/user/wishlistData/${userId}`);
      const result = await response.json();
      if (result.status === 1) {
        return result.data;
      } else {
        throw new Error('Failed to fetch wishlist data');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const removeFromWishlistAPI = createAsyncThunk(
  'wishlist/removeFromWishlistAPI',
  async ({ productId, userId }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${baseUrl}/user/remove/productFromWishlist/${productId}/${userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to remove item from wishlist');
      }

      return productId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// New async thunk for clearing all items using existing remove API
export const clearAllWishlistItems = createAsyncThunk(
  'wishlist/clearAllWishlistItems',
  async ({ userId, items }, { dispatch, rejectWithValue }) => {
    try {
      // Create an array of promises for each remove operation
      const removePromises = items.map(item => 
        dispatch(removeFromWishlistAPI({ productId: item._id, userId })).unwrap()
      );

      // Wait for all remove operations to complete
      await Promise.all(removePromises);
      
      return true;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState: {
    wishlistItems: [],
    status: 'idle',
    error: null,
    clearingStatus: 'idle',
  },
  reducers: {
    addToWishlist(state, action) {
      const isInWishlist = state.wishlistItems.findIndex(item => item._id === action.payload._id);
      
      if (isInWishlist > -1) {
        cogoToast.info("Product already in wishlist", { position: "top-center" });
      } else {
        state.wishlistItems.push(action.payload);
        cogoToast.success("Added To Wishlist", { position: "top-center" });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWishlistData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWishlistData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.wishlistItems = action.payload.wishlistProducts.wishlist || [];
      })
      .addCase(fetchWishlistData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        cogoToast.error("Failed to load wishlist", { position: "top-center" });
      })
      .addCase(removeFromWishlistAPI.fulfilled, (state, action) => {
        state.wishlistItems = state.wishlistItems.filter(item => item._id !== action.payload);
      })
      // New cases for clearing all items
      .addCase(clearAllWishlistItems.pending, (state) => {
        state.clearingStatus = 'loading';
      })
      .addCase(clearAllWishlistItems.fulfilled, (state) => {
        state.clearingStatus = 'succeeded';
        state.wishlistItems = [];
        cogoToast.success("Wishlist Cleared", { position: "top-center" });
      })
      .addCase(clearAllWishlistItems.rejected, (state, action) => {
        state.clearingStatus = 'failed';
        state.error = action.payload;
        cogoToast.error("Failed to clear wishlist", { position: "top-center" });
      });
  },
});

export const { addToWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer;










// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
// import cogoToast from 'cogo-toast';
// import { baseUrl } from '../../Constant/constant';

// // Helper function to get auth headers
// const authHeader = (getState) => {
//   const { user } = getState();
//   if (user.currentUser) {
//     return { Authorization: `Bearer ${user.currentUser.authenticationToken}` };
//   }
//   return {};
// };

// // Fetch Wishlist Data
// export const fetchWishlistData = createAsyncThunk(
//   'wishlist/fetchData',
//   async (_, { getState, rejectWithValue }) => {
//     const headers = authHeader(getState);
//     if (!headers.Authorization) {
//       return rejectWithValue('User not logged in');
//     }
//     try {
//       const response = await axios.get(`${baseUrl}/user/wishlistData/${getState().user.currentUser.id}`, { headers });
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Failed to fetch wishlist data');
//     }
//   }
// );

// // Add to Wishlist
// export const addToWishlistAsync = createAsyncThunk(
//   'wishlist/addToWishlist',
//   async (product, { getState, rejectWithValue }) => {
//     const headers = authHeader(getState);
//     if (!headers.Authorization) {
//       return rejectWithValue('User not logged in');
//     }
//     try {
//       await axios.post(`${baseUrl}/user/add/productToWishlist/${product.id}/${getState().user.currentUser.id}`, {}, { headers });
//       return product;
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'Failed to add product to wishlist');
//     }
//   }
// );

// // Slice
// const wishlistSlice = createSlice({
//   name: 'wishlist',
//   initialState: {
//     wishlistItems: [],
//     loading: false,
//     error: null,
//   },
//   reducers: {
//     deleteFromWishlist(state, action) {
//       state.wishlistItems = state.wishlistItems.filter(item => item.id !== action.payload);
//       cogoToast.error('Removed From Wishlist', { position: 'bottom-left' });
//     },
//     deleteAllFromWishlist(state) {
//       state.wishlistItems = [];
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchWishlistData.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(fetchWishlistData.fulfilled, (state, action) => {
//         state.loading = false;
//         state.wishlistItems = action.payload;
//       })
//       .addCase(fetchWishlistData.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload || 'Failed to fetch wishlist data';
//         cogoToast.error(state.error, { position: 'bottom-left' });
//       })
//       .addCase(addToWishlistAsync.fulfilled, (state, action) => {
//         const isInWishlist = state.wishlistItems.findIndex(item => item.id === action.payload.id);
//         if (isInWishlist === -1) {
//           state.wishlistItems.push(action.payload);
//           cogoToast.success('Added To Wishlist', { position: 'bottom-left' });
//         } else {
//           cogoToast.info('Product already in wishlist', { position: 'bottom-left' });
//         }
//       })
//       .addCase(addToWishlistAsync.rejected, (state, action) => {
//         state.error = action.payload || 'Failed to add product to wishlist';
//         cogoToast.error(state.error, { position: 'bottom-left' });
//       });
//   },
// });

// export const { deleteFromWishlist, deleteAllFromWishlist } = wishlistSlice.actions;
// export default wishlistSlice.reducer;







// import { createSlice } from '@reduxjs/toolkit';
// import cogoToast from 'cogo-toast';
// import axios from 'axios';
// import { baseUrl } from '../../Constant/constant';

// const wishlistSlice = createSlice({
//   name: "wishlist",
//   initialState: {
//     wishlistItems: []
//   },
//   reducers: {
//     setWishlistItems(state, action) {
//       state.wishlistItems = action.payload;
//     },
//     addToWishlist(state, action) {
//       const isInWishlist = state.wishlistItems.findIndex(item => item.id === action.payload.id);
//       if (isInWishlist > -1) {
//         cogoToast.info("Product already in wishlist", { position: "bottom-left" });
//       } else {
//         state.wishlistItems.push(action.payload);
//         cogoToast.success("Added To wishlist", { position: "bottom-left" });
//       }
//     },
//     deleteFromWishlist(state, action) {
//       state.wishlistItems = state.wishlistItems.filter(item => item.id !== action.payload);
//       cogoToast.error("Removed From Wishlist", { position: "bottom-left" });
//     },
//     deleteAllFromWishlist(state) {
//       state.wishlistItems = [];
//       cogoToast.info("Cleared all items from wishlist", { position: "bottom-left" });
//     }
//   },
// });

// export const { setWishlistItems, addToWishlist, deleteFromWishlist, deleteAllFromWishlist } = wishlistSlice.actions;
// export default wishlistSlice.reducer;






// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
// import cogoToast from 'cogo-toast';
// import { baseUrl } from '../../Constant/constant';

// export const addToWishlistAsync = createAsyncThunk(
//   'wishlist/addToWishlistAsync',
//   async ({ product, userId }, { rejectWithValue }) => {
//     try {
//       const response = await axios.post(`${baseUrl}/user/add/productToWishlist/${product.id}/${userId}`, {});
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// const wishlistSlice = createSlice({
//   name: 'wishlist',
//   initialState: {
//     wishlistItems: [],
//   },
//   reducers: {
//     setWishlistItems(state, action) {
//       state.wishlistItems = action.payload;
//     },
//     deleteFromWishlist(state, action) {
//       state.wishlistItems = state.wishlistItems.filter(item => item.id !== action.payload);
//       cogoToast.error("Removed from Wishlist", { position: "bottom-left" });
//     },
//     deleteAllFromWishlist(state) {
//       state.wishlistItems = [];
//       cogoToast.error("Cleared all Wishlist items", { position: "bottom-left" });
//     },
//   },
//   extraReducers: (builder) => {
//     builder.addCase(addToWishlistAsync.fulfilled, (state, action) => {
//       const product = action.payload;
//       const isInWishlist = state.wishlistItems.findIndex(item => item.id === product.id);
//       if (isInWishlist === -1) {
//         state.wishlistItems.push(product);
//         cogoToast.success("Added to wishlist", { position: "bottom-left" });
//       } else {
//         cogoToast.info("Product already in wishlist", { position: "bottom-left" });
//       }
//     });
//     builder.addCase(addToWishlistAsync.rejected, (state, action) => {
//       cogoToast.error("Failed to add to wishlist", { position: "bottom-left" });
//     });
//   },
// });

// export const { setWishlistItems, deleteFromWishlist, deleteAllFromWishlist } = wishlistSlice.actions;
// export default wishlistSlice.reducer;
