// import { v4 as uuidv4 } from 'uuid';
// import cogoToast from 'cogo-toast';
// const { createSlice } = require('@reduxjs/toolkit');

// const cartSlice = createSlice({
//     name: "cart",
//     initialState: {
//         cartItems: []
//     },
//     reducers: {
//         addToCart(state, action) {
//             const product = action.payload;
//             if(!product.variation){
//                 const cartItem = state.cartItems.find(item => item.id === product.id);
//                 if(!cartItem){
//                     state.cartItems.push({
//                         ...product,
//                         quantity: product.quantity ? product.quantity : 1,
//                         cartItemId: uuidv4()
//                     });
//                 } else {
//                     state.cartItems = state.cartItems.map(item => {
//                         if(item.cartItemId === cartItem.cartItemId){
//                             return {
//                                 ...item,
//                                 quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1
//                             }
//                         }
//                         return item;
//                     })
//                 }

//             } else {
//                 const cartItem = state.cartItems.find(
//                     item =>
//                         item.id === product.id &&
//                         product.selectedProductColor &&
//                         product.selectedProductColor === item.selectedProductColor &&
//                         product.selectedProductSize &&
//                         product.selectedProductSize === item.selectedProductSize &&
//                         (product.cartItemId ? product.cartItemId === item.cartItemId : true)
//                 );
//                 if(!cartItem){
//                     state.cartItems.push({
//                         ...product,
//                         quantity: product.quantity ? product.quantity : 1,
//                         cartItemId: uuidv4()
//                     });
//                 } else if (cartItem !== undefined && (cartItem.selectedProductColor !== product.selectedProductColor || cartItem.selectedProductSize !== product.selectedProductSize)) {
//                     state.cartItems = [
//                         ...state.cartItems,
//                         {
//                             ...product,
//                             quantity: product.quantity ? product.quantity : 1,
//                             cartItemId: uuidv4()
//                         }
//                     ]
//                 } else {
//                     state.cartItems = state.cartItems.map(item => {
//                         if(item.cartItemId === cartItem.cartItemId){
//                             return {
//                                 ...item,
//                                 quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1,
//                                 selectedProductColor: product.selectedProductColor,
//                                 selectedProductSize: product.selectedProductSize
//                             }
//                         }
//                         return item;
//                     });
//                 }
//             }

//             cogoToast.success("Added To Cart", {position: "bottom-left"});
//         },
//         deleteFromCart(state, action) {
//             state.cartItems = state.cartItems.filter(item => item.cartItemId !== action.payload);
//             cogoToast.error("Removed From Cart", {position: "bottom-left"});
//         },
//         decreaseQuantity(state, action){
//             const product = action.payload;
//             if (product.quantity === 1) {
//                 state.cartItems = state.cartItems.filter(item => item.cartItemId !== product.cartItemId);
//                 cogoToast.error("Removed From Cart", {position: "bottom-left"});
//             } else {
//                 state.cartItems = state.cartItems.map(item =>
//                     item.cartItemId === product.cartItemId
//                         ? { ...item, quantity: item.quantity - 1 }
//                         : item
//                 );
//                 cogoToast.warn("Item Decremented From Cart", {position: "bottom-left"});
//             }
//         },
//         deleteAllFromCart(state){
//             state.cartItems = []
//         }
//     },
// });

// export const { addToCart, deleteFromCart, decreaseQuantity, deleteAllFromCart } = cartSlice.actions;
// export default cartSlice.reducer;










//add to cart
// src/store/slices/cart-slice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import cogoToast from 'cogo-toast';
import { baseUrl } from '../../Constant/constant';
import axios from 'axios';

export const fetchCartData = createAsyncThunk('cart/fetchCartData', async (userId) => {
  const response = await axios.get(`${baseUrl}/user/get/cart/${userId}`);
  console.log("API response cart slice:", response.data);
  return response.data.cartItems || [];
});

export const removeFromCartAPI = createAsyncThunk(
  'cart/removeFromCartAPI',
  async ({ productId, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`${baseUrl}/user/remove/product/cart`, {
        productId,
        userId
      });

      if (!response.data.success) {
        throw new Error('Failed to remove item from cart');
      }

      return productId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const clearCartAPI = createAsyncThunk(
  'cart/clearCartAPI',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`${baseUrl}/user/clear/cart`, {
        userId
      });

      if (!response.data.success) {
        throw new Error('Failed to clear cart');
      }

      return response.data.cartItems || [];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cartItems: [],
    status: 'idle',
    error: null
  },
  reducers: {
    addToCart: (state, action) => {
      const item = action.payload;
      const existingItem = state.cartItems.find((i) => i.productId === item.productId);
      if (existingItem) {
        existingItem.quantity += item.quantity;
      } else {
        state.cartItems.push(item);
      }
    },
    decreaseQuantity: (state, action) => {
      const itemId = action.payload;
      const item = state.cartItems.find((i) => i.productId === itemId);
      if (item && item.quantity > 1) {
        item.quantity--;
      }
    },
    deleteFromCart: (state, action) => {
      const itemId = action.payload;
      state.cartItems = state.cartItems.filter((i) => i.productId !== itemId);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCartData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cartItems = action.payload;
      })
      .addCase(fetchCartData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(removeFromCartAPI.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeFromCartAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cartItems = state.cartItems.filter(item => item.productId !== action.payload);
        cogoToast.error("Removed From Cart", { position: "bottom-left" });
      })
      .addCase(removeFromCartAPI.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        cogoToast.error(action.payload, { position: "bottom-left" });
      })
      .addCase(clearCartAPI.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(clearCartAPI.fulfilled, (state) => {
        state.status = 'succeeded';
        state.cartItems = [];
        cogoToast.error("Cart Cleared", { position: "bottom-left" });
      })
      .addCase(clearCartAPI.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        cogoToast.error(action.payload, { position: "bottom-left" });
      })},
      deleteAllFromCart(state){
        state.cartItems = []
    }
  
});

export const { addToCart, deleteFromCart, decreaseQuantity, deleteAllFromCart } = cartSlice.actions;
export default cartSlice.reducer;