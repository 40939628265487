import { Suspense, lazy, useContext, useEffect, useState } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import axios from "axios";
// import { AuthContext } from "./context/auth.js";
// import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrency } from "./store/slices/currency-slice.js";
import "../src/App.css";
import axios from "axios";
import { AuthContext } from "./context/auth.js";
import { baseUrl } from "./Constant/constant.js";
import ReactGA from "react-ga4";



const HomeOrganicFoodTwo = lazy(() => import("./pages/home/HomeOrganicFoodTwo"));
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));
const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridRightSidebar = lazy(() => import("./pages/shop/ShopGridRightSidebar"));
const ShopListStandard = lazy(() => import("./pages/shop/ShopListStandard"));
const ShopListFullWidth = lazy(() => import("./pages/shop/ShopListFullWidth"));
const ShopListTwoColumn = lazy(() => import("./pages/shop/ShopListTwoColumn"));
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() => import("./pages/shop-product/ProductTabLeft"));
const ProductTabRight = lazy(() => import("./pages/shop-product/ProductTabRight"));
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() => import("./pages/shop-product/ProductFixedImage"));
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() => import("./pages/blog/BlogDetailsStandard"));
const About = lazy(() => import("./pages/other/About"));
const SupportPolicyDetails = lazy(() => import("./components/section-title/SupportPolicyDetails"));
const PrivacyPolicy = lazy(() => import("./components/section-title/PrivacyPolicy"));
const ReturnandCancellation = lazy(() => import("./components/section-title/ReturnandCancellation"));
const PaymentPolicy = lazy(() => import("./components/section-title/PaymentPolicy"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const MyOrder = lazy(() => import("./pages/other/MyOrder"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const ShippingAdress = lazy(() => import("./pages/other/ShippingAdress"));
const OrderConfirmation = lazy(() => import("./pages/other/OrderConfirmation.js"));
const NotFound = lazy(() => import("./pages/other/NotFound"));


const App = () => {
    const { userData, vendorId } = useContext(AuthContext);
    const [ga, setGa] = useState("");
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setCurrency("INR"));
    }, [dispatch]);

    useEffect(() => {
        fetchWishlistIconColor();
        fetchAdminDetails()
        
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "" });
    }, [vendorId]);


    const fetchAdminDetails = async () => {
        try {
            const vendorResponse = await axios.get(
                `${baseUrl}/vendor/get-details/${vendorId}`
            );
            setGa(vendorResponse.data.data.basicInformation.ga);
            ReactGA.initialize(ga);
            console.log('asda',vendorResponse.data)
        } catch (err) {
            console.error("Error fetching details: ", err);
        }
    };

    const fetchWishlistIconColor = async () => {
        try {
            if (vendorId) {
                const response = await axios.get(
                    `${baseUrl}/user/get/color/${vendorId}`
                );
                const colorData = response.data.data;
                localStorage.setItem("WebsiteColor", JSON.stringify(colorData));
            }
        } catch (error) {
            console.error("Error fetching wishlist icon color:", error);
        }
    };


    return (
        <Router>
            <ScrollToTop>
                <Suspense
                    fallback={
                        <div className="flone-preloader-wrapper">
                            <div className="flone-preloader">
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    }
                >
                    <Routes>
                        <Route path={process.env.PUBLIC_URL + "/"} element={<HomeOrganicFoodTwo />}/>
                        <Route
                            path={process.env.PUBLIC_URL + "/home-organic-food-two"}
                            element={<HomeOrganicFoodTwo />}
                        />

                        {/* Shop pages */}
                        <Route
                            path={
                                process.env.PUBLIC_URL +
                                "/shop-grid-standard/:categoryId/:subCategoryId?"
                            }
                            element={<ShopGridStandard />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/shop-grid-standard"}
                            element={<ShopGridStandard />}
                        />

                        <Route
                            path={process.env.PUBLIC_URL + "/shop-grid-filter"}
                            element={<ShopGridFilter />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/shop-grid-two-column"}
                            element={<ShopGridTwoColumn />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}
                            element={<ShopGridNoSidebar />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                            element={<ShopGridFullWidth />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/shop-grid-right-sidebar"}
                            element={<ShopGridRightSidebar />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/shop-list-standard"}
                            element={<ShopListStandard />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/shop-list-full-width"}
                            element={<ShopListFullWidth />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/shop-list-two-column"}
                            element={<ShopListTwoColumn />}
                        />

                        {/* Shop product pages */}
                        <Route
                            path={process.env.PUBLIC_URL + "/product/:id"}
                            element={<Product />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/product-tab-left/:id"}
                            element={<ProductTabLeft />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/product-tab-right/:id"}
                            element={<ProductTabRight />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/product-sticky/:id"}
                            element={<ProductSticky />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/product-slider/:id"}
                            element={<ProductSlider />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
                            element={<ProductFixedImage />}
                        />

                        {/* Blog pages */}
                        <Route
                            path={process.env.PUBLIC_URL + "/blog-standard"}
                            element={<BlogStandard />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                            element={<BlogNoSidebar />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/blog-right-sidebar"}
                            element={<BlogRightSidebar />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/blog-details-standard"}
                            element={<BlogDetailsStandard />}
                        />

                        {/* Other pages */}
                        <Route
                            path={process.env.PUBLIC_URL + "/about"}
                            element={<About />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/contact"}
                            element={<Contact />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/my-account"}
                            element={<MyAccount />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/my-order"}
                            element={<MyOrder />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/login-register"}
                            element={<LoginRegister />}
                        />
                        <Route
                            path={process.env.PUBLIC_URL + "/policy/:policyId"}
                            element={<SupportPolicyDetails />}
                        />

                        <Route path={process.env.PUBLIC_URL + "/cart"} element={<Cart />} />
                        <Route
                            path={process.env.PUBLIC_URL + "/wishlist"}
                            element={<Wishlist />}
                        />
                        {/* <Route
                path={process.env.PUBLIC_URL + "/compare"}
                element={<Compare/>}
              /> */}
                        <Route
                            path={process.env.PUBLIC_URL + "/checkout"}
                            element={<Checkout />}
                        />

                        <Route
                            path={`/order-confirmation/:orderId`}
                            element={<OrderConfirmation />}
                        />

                        <Route
                            path={process.env.PUBLIC_URL + "/shippingaddress"}
                            element={<ShippingAdress />}
                        />

                        {/* <Route path="*" element={<NotFound/>} /> */}
                    </Routes>
                </Suspense>
            </ScrollToTop>
        </Router>
    );
};

export default App;
